import React, { useState, useRef,useEffect } from "react";
import { Camera } from "react-camera-pro";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { updatedata } from "../Redux/reduxflow/action";
import baseUrlNew from "../BaseUrl/index"
import ReactPlayer from "react-player";
import video from '../assets/hatchback-v3-motorway-400kbs-HQ.mov';
import SafeArea from 'react-safe-area-component'
import video_android from '../assets/hatchback-v3-1104x828-30fps.webm'
import Hatchback from '../assets/hatchback-v3-1104x828-30fps.webm'
import SUV from '../assets/suv-v4-motorway-400kbs-HQ.webm'
import Salon from '../assets/saloon-v3-1104x828-30fps.webm'
import HatchbackIphone from '../assets/hatchback-v3-motorway-400kbs-HQ.mov'
import SUVIphone from '../assets/suv-v4-motorway-400kbs-HQ.mov'
import SalonIphone from '../assets/saloon-v3-motorway-400kbs-HQ.mov'
import { hatchback, videoSteps } from "../CameraWithOverlayVideo/data";
function Camra({ updatedata,data,index,state,view,index_of_data,car_type }) {
  // console.log("data in camera ",data)
  const camera = useRef(null);
  const [image, setImage] = useState(null);

  let history = useHistory();
  console.log("camera 2222222222222222-------------> ",car_type)
  const location = useLocation();
  const videoPlayer = useRef(null);
  const video_index_ref = React.useRef(0);
  const [playVideo, setplayVideo] = React.useState(false);
  const [seekdone, setseekdone] = React.useState(false);
  const [video_url, setvideo_url] = useState(Hatchback);
  const requestRef = React.useRef();
  const previousTimeRef = React.useRef(true);
  const arr = [].concat.apply([], Object.values(videoSteps));
  // const [variant_id, setVariant_id] = useState(location.state);

  // const [type, settype] = useState(location.state);

  // console.log(type, "camra page", location.state.image_id);
  useEffect(() => {
    if (window.navigator.platform == "iPhone") {

      if (car_type == '1') {
        setvideo_url(HatchbackIphone)
      } else if (car_type == '2') {
          setvideo_url(SalonIphone)
      } else if ( car_type == '3') {
          setvideo_url(SalonIphone)
       }

    } else {

      if (car_type == '1') {
          setvideo_url(Hatchback)
      } else if (car_type == '2') {
          setvideo_url(Salon)
      }  else if ( car_type == '3') {
          setvideo_url(Salon)
       }
    }

    
  }, [car_type]);

  const Post_imge=(image)=> {
    const baseUrl = `${baseUrlNew.baseUrl}api/v1/update_evaluation_images_Details/${data.id}`;

    axios
      .put(baseUrl, {
        key: data.key,
        type: data.type,
        image_data: image,
      })
      .then((response) => {
        updatedata(response.data, index);
    
        state(false)
        view(false)
        // setseekdone(true);
        // history.push("/cardamage");
        // console.log("api response", response.data);
      });
  }

  const Uploadpic = () => {
    // settype(variant_id.type)
    // setImage(camera.current.takePhoto());
    setseekdone(false);
    Post_imge(camera.current.takePhoto());
  
    // console.log(type, "camra  xxx pic", data);
  };


const donecamra=()=>{
  // history.push("/cardamage")
  view(false)
  state(false)
}


  return (
    <>
      <div className="container_">
        <div className="camera_main_cont">
          <div className="camera_wrapper">
          <div
          onClick={donecamra}
           
            style={{top: "12px",left: "10px",position: "absolute", zIndex: "1000",color: "white",fontSize: "1.4rem"}}
            className="done">Done</div>
            <Camera
              facingMode="environment"
              width="100%"
              height="100%"
              ref={camera}
            />
              <div className="video_wrapper">
              
              <ReactPlayer
                ref={videoPlayer}
                playing={playVideo}
                style={!seekdone?{"display": "None"}:{}}
                // playing={true}/
                playbackRate={4}
                width="90%"
                height="90%"
                playsinline 
                onReady={()=>{
                  // console.log("asdasd -->> ",index_of_data)
                  videoPlayer.current.seekTo(arr[index_of_data].stop_at,'seconds')
                  setseekdone(true)
                }}
                // onProgress={(e) => videoPlayerHandler(e)}
                // onSeek={(e) => console.log("react player onseek", e)}
                url={video_url}
                // url="https://firebasestorage.googleapis.com/v0/b/auction-85e6c.appspot.com/o/suv-v4-30fps-1104x828.webm?alt=media&token=61a99d4c-6de4-45a3-9803-49ea470efa41"
                // url="https://firebasestorage.googleapis.com/v0/b/auction-85e6c.appspot.com/o/saloon-v3-1104x828-30fps%20(1).webm?alt=media&token=c060073c-1055-48af-83f2-3653b20be68a"
              />
            </div>
          </div>

          <div className="tool_bar">
          {seekdone && (
            <button
              className="round_button flex_center"
              //   onClick={Uploadpic}
              onClick={() => Uploadpic()}
              //   onClick={() => take_imge(currentImage)}
            >
              <div className="round_button_ flex_center" />
            </button>
          )}

            {/* <button onClick={videoPlayerHandler}>play/pause</button> */}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  car_data: state.cardata.car_pics_data,
  loading: state.cardata.loading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    updatedata: (response, variant_id) =>
      dispatch(updatedata(response, variant_id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Camra);
