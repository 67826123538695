
    import { ADD_CAR_DATA,UPDATE_CAR_DATA, DECREMENT,UPDATE_IMAGES_,ADD_CAR_DATA_MAIN,ADD_DAMAGE_DATA_MAIN } from './types';


    export const getcardata = (data) => {
        // console.log("data in action",data )

        return {

            type: ADD_CAR_DATA,
            payload:data

        };

    };
    export const setsingleimage = (data) => {
        // console.log("data in action",data )

        return {

            type: "SUBMIT_SINGLE_IMAGE",
            payload:data

        };

    };
    export const updatedata = (data,index) => {
        // console.log(index,"data in action",data )

        return {

            type: UPDATE_CAR_DATA,
            payload:data,index

        };

    };
    export const add_Damagedata = (data,) => {
        // console.log(index,"data in action",data )

        return {

            type: ADD_DAMAGE_DATA_MAIN,
            payload:{"data":data}

        };

    };
    export const add_newdata = (data,) => {
        // console.log(index,"data in action",data )

        return {

            type: ADD_CAR_DATA_MAIN,
            payload:{"data":data}

        };

    };
    export const update_car_imges = (data,index) => {
        // console.log(index,"update_car_imges data in action",data )

        return {

            type: UPDATE_IMAGES_,
            payload:data

        };

    };

    