import { React, useState } from "react";
import { useHistory ,useLocation} from "react-router-dom";
import { AiFillCar } from "react-icons/ai";
import "./style.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
// ES6
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import baseUrlNew from "../BaseUrl/index"
import { connect, useSelector } from "react-redux";
import Car1 from "../assets/images/cara.png";
import Car2 from "../assets/images/carb.png";
import Car3 from "../assets/images/carc.png";
import Car4 from "../assets/images/card.png";
import Car5 from "../assets/images/care.png";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import DeskTopView from "../Desktopview"
import { add_Damagedata } from "../Redux/reduxflow/action";
require("@lottiefiles/lottie-player");




function Appoinmentupdate({id,image,close,add_Damagedata}) {



  let history = useHistory();
  const location = useLocation();






// const [car_imge_, setcar_imge_] = useState(location.state.data)



// console.log("car image =======>>>>",car_imge_)






// =========================api call section ==============

// console.log("base url export",baseUrlNew)
const baseUrl =`${baseUrlNew.baseUrl}api/v1/damageimages_base/`;
// console.log("base url after  export",baseUrl)
const PostCarimge=()=> {
  
  var ship =document.getElementById("ship")
axios
  .post(baseUrl, {
    left:ship.style.left,
    top:ship.style.top,
    evaluate_damageimage:id,
    image_data:image,
    radius:ship.style.height+","+ship.style.width


  })
  .then((response) => {
    setloding(false)
    add_Damagedata(response.data.data)
    close(false)
    // console.log("api response", response.data);
  });
}



const [loding, setloding] = useState(false)

const carcamara_page=()=>{
  setloding(true)
  PostCarimge()
  // history.push("/cardamagecamra")

}


  const [imge, setimg] = useState(Car3);
  const [current, setcurrent] = useState("front");
  const [left, setleft] = useState("310px");
  const [top, settop] = useState("50px");
  const [slider, setslider] = useState(20);
  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );

  // console.log("chk the sreen state  =====>>>>>>", sreen);

  const preventHorizontalKeyboardNavigation=(event)=> {
    // console.log("sliderqqqqqqqqqq1", event);
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
      // console.log("sliderqqqqqqqqqq2", event);
    }
    // console.log("sliderqqqqqqqqqq3", event);
  }

  const handleChange = (e) => {
    // console.log("sliderqqqqqqqqqq3", e, e.target.value);
    const myElement = document.getElementById("ship");
    myElement.style.height = `${e.target.value}px`;
    myElement.style.width = `${e.target.value}px`;
    setslider(e.target.value)
    myElement.style.minHeight = "15px";
    myElement.style.minWidth = "15px";

    myElement.style.maxWidth = "80px";
    myElement.style.maxHeight = "80px";
  };

  const Change_Image1 = () => {
    setcurrent("front")
    document.getElementById("imge_wrap").style.width = "30%";
    setimg(Car3);
    const myElement = document.getElementById("aa");
    myElement.style.background = "green";
    document.getElementById("ab").style.background = "gray";
    document.getElementById("ac").style.background = "gray";
    document.getElementById("ad").style.background = "gray";
    document.getElementById("ae").style.background = "gray";




    document.getElementById("ab_").style.fill = "black";

    document.getElementById("ac_").style.fill = "black";
    document.getElementById("ad_").style.fill = "black";
    document.getElementById("ae_").style.fill = "black";
    document.getElementById("aa_").style.fill = "white";
  };
  const Change_Image2 = () => {
    setcurrent("passenger")
    setimg(Car5);
    document.getElementById("imge_wrap").style.width = "50%";

    document.getElementById("aa").style.background = "gray";
    document.getElementById("ab").style.background = "green"; 
    document.getElementById("ac").style.background = "gray";
    document.getElementById("ad").style.background = "gray";
    document.getElementById("ae").style.background = "gray";


        document.getElementById("ab_").style.fill = "white";

        document.getElementById("ac_").style.fill = "black";
        document.getElementById("ad_").style.fill = "black";
        document.getElementById("aa_").style.fill = "black";
        document.getElementById("ae_").style.fill = "black";
  };
  const Change_Image3 = () => {
    setcurrent("back")
    setimg(Car4);
    document.getElementById("imge_wrap").style.width = "30%";
    document.getElementById("aa").style.background = "gray";
    document.getElementById("ab").style.background = "gray";
    document.getElementById("ac").style.background = "green";
    document.getElementById("ad").style.background = "gray";
    document.getElementById("ae").style.background = "gray";



        document.getElementById("ab_").style.fill = "black";

        document.getElementById("ac_").style.fill = "white";
        document.getElementById("ad_").style.fill = "black";
        document.getElementById("aa_").style.fill = "black";
        document.getElementById("ae_").style.fill = "black";
  };
  const Change_Image4 = () => {
    setcurrent("driver")
    setimg(Car1);
    document.getElementById("imge_wrap").style.width = "50%";
    document.getElementById("aa").style.background = "gray";
    document.getElementById("ab").style.background = "gray";
    document.getElementById("ac").style.background = "gray";
    document.getElementById("ad").style.background = "green";
    document.getElementById("ae").style.background = "gray";

    document.getElementById("ab_").style.fill = "black";

    document.getElementById("ac_").style.fill = "black";
    document.getElementById("ad_").style.fill = "white";
    document.getElementById("ae_").style.fill = "black";
    document.getElementById("aa_").style.fill = "black";



  };
  const Change_Image5 = () => {
    setcurrent("top")
    setimg(Car2);

    document.getElementById("imge_wrap").style.width = "50%";
    document.getElementById("aa").style.background = "gray";
    document.getElementById("ab").style.background = "gray";

    document.getElementById("ac").style.background = "gray";
    document.getElementById("ad").style.background = "gray";
    document.getElementById("ae").style.background = "green";

    document.getElementById("ab_").style.fill = "black";

    document.getElementById("ac_").style.fill = "black";
    document.getElementById("ad_").style.fill = "black";
    document.getElementById("aa_").style.fill = "black";
    document.getElementById("ae_").style.fill = "white";
  };
    const set_state = ()=>{
      const myElement = document.getElementById("ship");
      myElement.style.height = `${slider}px`;
      myElement.style.width = `${slider}px`;
      switch(current){
        case "front":

          Change_Image1()
          break;
        case "back":
          Change_Image3()
          break;
        case "passenger":
          Change_Image2()
          break;
        case "driver":
          Change_Image4()
          break;
        case "top":
          Change_Image5()
          break;

      }
    }
  // window.addEventListener("load", () => {
    // console.log("LOadiddi")
    const doOnOrientationChange=()=> {
      // console.log("orientation ", window.orientation);
      switch (window.orientation) {
        // case -90:
        case 90:
          setsreen("landscap");
          set_state()

          // console.log("landcap =========>>>>>", window.orientation);
          break;
        default:
          setsreen("portrait");

          // console.log("portrait =========>>>>", window.orientation);

          break;
      }
    }

    window.addEventListener("orientationchange", doOnOrientationChange);

    // var ship = document.getElementById("ship");

    // document
    //   .getElementById("imge_wrap")
    //   .addEventListener("touchstart", handleTouchEvent, true);
    // document
    //   .getElementById("imge_wrap")
    //   .addEventListener("touchmove", handleTouchEvent, true);
    // document
    //   .getElementById("imge_wrap")
    //   .addEventListener("touchend", handleTouchEvent, true);
    // document
    //   .getElementById("imge_wrap")
    //   .addEventListener("touchcancel", handleTouchEvent, true);

    // ship.addEventListener("touchstart", handleTouchEvent, true);
    // ship.addEventListener("touchmove", handleTouchEvent, true);
    // ship.addEventListener("touchend", handleTouchEvent, true);
    // ship.addEventListener("touchcancel", handleTouchEvent, true);

    // will adjust ship's x to latest touch
    const handleTouchEvent=(e)=> {
      
      document.getElementById("fingrr").style.display = "none";


      var scrollPosition = window.pageYOffset;
      
      
      
      
      // if(e._reactName=="onTouchStart"){
      //       document.body.classList.add("stop-scrolling");
      //     }
      // console.log("Touch event");
      // console.log(e)


      // console.log(e.target.id)
      if (e.touches.length === 0) {
        if(e._reactName=="onTouchEnd"){
          // document.body.classList.remove("stop-scrolling");
        }
        return}
      var touch = e.touches[0];
      var targetT = document.elementFromPoint(touch.clientX, touch.clientY);
      // console.log("targetT ",targetT.id)
      if(targetT.id=="imge_wrap"){


      
      var ship = document.getElementById("ship");
      var tA = document.getElementById("overmain");

      const elemWidth = ship.getBoundingClientRect().width;
      const parentWidth = ship.parentElement.getBoundingClientRect().width;

      // console.log( "Element overflow ",elemWidth > parentWidth)
      // console.log("Overflow width ",tA.scrollWidth > tA.clientWidth)
      // console.log("Overflow height",tA.scrollHeight > tA.clientHeight)

      // console.log("Overflow width 2",tA.scrollWidth < tA.clientWidth)
      // console.log("Overflow height 2",tA.scrollHeight < tA.clientHeight)
      var clientRectangle = ship.getBoundingClientRect();

      // console.log(parseFloat(ship.style.top));
      var y = clientRectangle.top - parseFloat(ship.style.top);
      var x = clientRectangle.left - parseFloat(ship.style.left);
      // console.log(
      //   "Clietn left and right ",
      //   clientRectangle.top,
      //   clientRectangle.left
      // );
      // console.log("ship left and right ", ship.style.top, ship.style.left);
      // var positionInfo = ship.getBoundingClientRect();
      // console.log("height ,width ",ship.offsetWidth,ship.offsetHeight,)

      // var mul = positionInfo.width*(2.3/50)
      // console.log("multi ",mul)
      // var mul2 = positionInfo.height*(2.3/50)
      e.preventDefault();
      e.stopPropagation();
      // console.log("Target ", e);
  
      // console.log("tagetT ",targetT)
      // var target =touch.target;
      // console.log("width ",touch.pageX,ship.width,(touch.pageX - ship.width /2));
      // console.log("Height ",touch.pageY,ship.height,(touch.pageY -ship.height/2));
      // console.log("x y ", x, y);
      // console.log("px py ", touch.pageX, touch.pageY);

      // var main = document.getElementById("overmain");
      // var screenWidth = main.getBoundingClientRect().width;
      // var screenheight =main.getBoundingClientRect().height;
      // // console.log("Touch ",(touch.pageX - x) ,screenWidth)
      // // ( screenWidth - pixels ) / screenWidth ;
      // // console.log("screen width ",screenWidth,touch.pageX,screenWidth -touch.pageX," x ",x)
      // var percentagex = ( screenWidth -touch.pageX) / screenWidth;
      // // console.log("per ",percentage)
      // ship.style.left = Math.abs((1-percentagex.toFixed(5))*100) + "%";
      // // console.log(ship.style.left);
      // // console.log("screen height ",screenheight,touch.pageY,screenheight -touch.pageY," y ",y)
      // var percentagey = ( screenheight -touch.pageY) / screenheight;
      // // console.log("per ",percentage)
      // ship.style.top = Math.abs((1-percentagey.toFixed(5))*100) + "%";

      // var Npercentage = ( screenWidth -x) / screenWidth;
      //   console.log("Match x ",Math.abs((1-percentagex.toFixed(5))*100) , Math.abs((1-Npercentage.toFixed(5))*100),Math.abs((1-percentagex.toFixed(5))*100) + Math.abs((1-Npercentage.toFixed(5))*100),Math.abs((1-percentagex.toFixed(5))*100) - Math.abs((1-Npercentage.toFixed(5))*100))
      // var Npercentage = ( screenheight -y) / screenheight;
      // console.log("Match x ",Math.abs((1-percentagey.toFixed(5))*100) , Math.abs((1-Npercentage.toFixed(5))*100),Math.abs((1-percentagey.toFixed(5))*100) + Math.abs((1-Npercentage.toFixed(5))*100),Math.abs((1-percentagey.toFixed(5))*100) - Math.abs((1-Npercentage.toFixed(5))*100))

      
      // const  main =document.getElementById('overmain')
      // console.log("main ",main.getBoundingClientRect().width)
      // console.log("screen width ",window.screen.width)
      ship.style.left = touch.pageX - x + "px";
      
      ship.style.top =(touch.pageY - y)-scrollPosition + "px";
      setleft(ship.style.left)
      settop(ship.style.top)
      //  console.log(ship.style.top);
      }
      if(e._reactName=="onTouchEnd"){
        document.body.classList.remove("stop-scrolling");
      }
    }
  // });

  return (
    <>
      <div className="container-fluied aaa-">
        <BrowserView>
          <DeskTopView />
        </BrowserView>

        <MobileView>
          {sreen == "landscap" ? (
            <div className="row car_Main_Wrap mt-4">
              <div className="col-9 ">
                <div
                  className="Car_Pic_Wrap pt-1"
                  id="overmain"
                  // style={{ width: "100%" }}
                >
                  <div
                    className="buble"
                    id="ship"
                    style={{
                      top: `${top}`,
                      left: `${left}`,
                      width: "30px",
                      height: "30px",
                    }}
                    onTouchMove={handleTouchEvent}
                    onTouchCancel={handleTouchEvent}
                    onTouchStart={handleTouchEvent}
                    onTouchEnd={handleTouchEvent}
                  ></div>

                  <img
                    className="car_Pic_"
                    id="imge_wrap"
                    src={imge}
                    onTouchMove={handleTouchEvent}
                    onTouchCancel={handleTouchEvent}
                    onTouchStart={handleTouchEvent}
                    onTouchEnd={handleTouchEvent}
                  />
                  <div className="mt- lot-wrap" id="fingrr"
                       onTouchMove={handleTouchEvent}
                       onTouchCancel={handleTouchEvent}
                       onTouchStart={handleTouchEvent}
                       onTouchEnd={handleTouchEvent}
                  >
                    <lottie-player
                      src="https://assets1.lottiefiles.com/packages/lf20_wvokzliq.json"
                      // background="#48BA7E"
                      speed="1"
                      // color="red"
                      style={{ height: "20vh" }}
                      loop
                      autoplay
                    ></lottie-player>
                  </div>
                </div>

                <div className="row my-5 btn_wrap">
                  <div
                    className="col-2"
                    onClick={() => {
                      Change_Image1();
                    }}
                  >
                    <div
                      className="icon_div gre"
                      id="aa"
                      style={{
                        backgroundColor: "green",
                      }}
                    >
                      <svg width={24} height={24} viewBox="0 0 24 24">
                        <path
                          style={{
                            fill: "white",
                          }}
                          id="aa_"
                          d="M16.48 17.07l.6 1.29-1.36.64-.2-.42H8.47l-.2.42-1.35-.64.6-1.29h8.95zM12.02 5c1.79 0 3.58.14 5.37.43l.49.08.46.08 1.08 3.67 1.23-.65.7 1.33-1.27.67.42.85v7.37H19v-7.01l-.58-1.17-.1.02a33 33 0 01-6.31.7c-2.04.01-4.2-.19-6.48-.6L5 11.8v7.02H3.5v-7.37l.42-.85-1.27-.67.7-1.33 1.23.65 1.09-3.67.45-.08a34.6 34.6 0 015.9-.51zM8.5 13.3v1.5h-2v-1.5h2zm9 0v1.5h-2v-1.5h2zm-3.5 0v1.5h-4v-1.5h4zm-1.98-6.8c-1.56 0-3.13.12-4.7.35l-.49.07-.71 2.42c2.07.36 4.03.53 5.88.52 1.86-.02 3.81-.23 5.85-.63l-.69-2.3a32.45 32.45 0 00-4.68-.42h-.46z"
                          className="sellanywheel-svg-fill"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>

                    <p className="car_text my-2 text-center">Front</p>
                  </div>
                  <div className="col-3" onClick={Change_Image2}>
                    <div className="icon_div" id="ab">
                      <svg width={24} height={24} viewBox="0 0 24 24">
                        <path
                          id="ab_"
                          d="M18 12.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-12 0a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm12 1.5a.75.75 0 110 1.5.75.75 0 010-1.5zm-12 0a.75.75 0 110 1.5.75.75 0 010-1.5zM10.34 6L4.88 9.5h-2L1 11.53v3.97h1.5v-3.39L3.53 11H18.3l1.23-1.93 1.98 1.23v5.2H23V9.46L17.42 6h-7.08zm-.84 8v1.5h5V14h-5zm5.3-6.5h2.19l1.1.69-.78 1.31H14.8v-2zm-4.02 0h2.51v2H7.66l3.12-2z"
                          className="sellanywheel-svg-fill"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="car_text my-2 text-center">Passenger </p>
                  </div>
                  <div className="col-2" onClick={Change_Image3}>
                    <div className="icon_div" id="ac">
                      <svg width={24} height={24} viewBox="0 0 24 24">
                        <path
                          id="ac_"
                          d="M16.48 17.07l.6 1.29-1.36.64-.2-.42H8.47l-.2.42-1.35-.64.6-1.29h8.95zM12.02 5c1.79 0 3.58.14 5.37.43l.49.08.46.08 1.08 3.67 1.23-.65.7 1.33-1.27.67.42.85v7.37H19V13.4l-1.72.5-.42-1.45 2.14-.61v-.01l-.58-1.17-.1.02a33 33 0 01-6.31.7c-2.04.01-4.2-.19-6.48-.6L5 11.8l2.16.63-.42 1.44-1.74-.5v5.45H3.5v-7.37l.42-.85-1.27-.67.7-1.33 1.23.65 1.09-3.67.45-.08C8.1 5.17 10.06 5 12.02 5zM14 13.3v1.5h-4v-1.5h4zm-1.98-6.8c-1.56 0-3.13.12-4.7.35l-.49.07-.71 2.42c2.07.36 4.03.53 5.88.52 1.86-.02 3.81-.23 5.85-.63l-.69-2.3a32.45 32.45 0 00-4.68-.42h-.46z"
                          className="sellanywheel-svg-fill"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="car_text my-2 text-center">Back</p>
                  </div>
                  <div className="col-2" onClick={Change_Image4}>
                    <div className="icon_div" id="ad">
                      <svg width={24} height={24} viewBox="0 0 24 24">
                        <path
                          id="ad_"
                          d="M6 12.75a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zm12 0a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zm-12 1.5a.75.75 0 100 1.5.75.75 0 000-1.5zm12 0a.75.75 0 100 1.5.75.75 0 000-1.5zM13.66 6l5.46 3.5h2L23 11.53v3.97h-1.5v-3.39L20.47 11H5.7L4.48 9.07 2.5 10.3v5.2H1V9.46L6.58 6h7.08zm.84 8v1.5h-5V14h5zM9.2 7.5H7.02l-1.1.69.78 1.31H9.2v-2zm4.02 0h-2.51v2h5.63l-3.12-2z"
                          className="sellanywheel-svg-fill"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="car_text my-2 text-center">Driver</p>
                  </div>
                  <div className="col-2" onClick={Change_Image5}>
                    <div className="icon_div" id="ae">
                      <svg width={24} height={24} viewBox="0 0 24 24">
                        <path
                          id="ae_"
                          d="M8.68 2.6L10.64 6h4.14l-1.41-2.67 1.33-.7L16.47 6h5.14L23 7.44v9.2L21.6 18h-3.47v.01L18.1 18h-1.75l-1.65 3.14-1.33-.7L14.66 18H10.5l-1.83 3.17-1.3-.75L8.78 18H6.4l-.03.01V18h-4L1 16.62v-9.2L2.39 6H8.9L7.38 3.35l1.3-.74zm-2.9 4.9H3.01l-.52.53V16l.5.5h3l4.09-2.35.2-.04h4.04l4.17 2.39H21l.5-.5V8.06l-.53-.55h-2.25l-4.39 2.51h-4.05l-.2-.04-4.3-2.47zm5.74 8.12h-.96L9 16.5h2.5v-.89zm2.41 0h-.91v.88h2.46l-1.55-.89zm5.78-7.75l.28.96c.6 2.09.6 4.3 0 6.38l-.27.93-2.97-1.75.02-2.3-.02-2.48 2.96-1.74zm-.94 2.3l-.52.3.02 1.61-.02 1.46.52.3c.22-1.2.22-2.44 0-3.64v-.04zM15.7 7.5h-2.68v1.01h.91L15.7 7.5zm-4.18 0H8.79l1.77 1.01h.96V7.5z"
                          className="sellanywheel-svg-fill"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="car_text my-2 text-center">Top</p>
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="row">
                  <div className="col-3 mt-2 pt-">
                    <Box sx={{ height: 200 }}>
                      <Slider
                        sx={{
                          '& input[type="range"]': {
                            WebkitAppearance: "slider-vertical",
                          },
                        }}
                        min={15}
                        max={80}
                        orientation="vertical"
                        defaultValue={slider}
                        aria-label="Temperature"
                        onKeyDown={preventHorizontalKeyboardNavigation}
                        onChange={handleChange}
                      />
                    </Box>
                  </div>
                  <div className="col-9  ">
                    <div className="my- pt-1">
                      <span className="heading-size"> Large </span>
                      <h6>16cm+</h6>
                    </div>
                    <div className="my- pt-1">
                      <span className="heading-size"> Medium</span>
                      <h6>6-15cm</h6>
                    </div>
                    <div className="my- pt-1">
                      <span className="heading-size">Small</span>
                      <h6>0.52cm </h6>
                    </div>

                    {loding ? (
                      <div>
                        <ClipLoader color="white" size={30} />
                      </div>
                    ) : (
                      <button className="submit_btn" onClick={carcamara_page}>
                        {" "}
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="Lotie_Main_Wrap">
              <div className="lotie_wrap">
                <lottie-player
                  src="https://assets10.lottiefiles.com/private_files/lf30_uqnbeq1u.json"
                  background="#48BA7E"
                  speed="1"
                  style={{ height: "92vh" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>
          )}
        </MobileView>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  car_data: state.cardata.car_pics_data,
  loading: state.cardata.loading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    add_Damagedata: (response) => dispatch(add_Damagedata(response)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Appoinmentupdate);;
