import { React, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  MdOutlineModeEditOutline,
  MdOutlineDirectionsCar,
} from "react-icons/md";
import "./style.css";
import DeskTopView from "../Desktopview";
import axios from "axios";
import { connect, useSelector } from "react-redux";

import { getcardata } from "../Redux/reduxflow/action";
import camra from "../Camra"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { ShimmerPostList,ShimmerThumbnail  } from "react-shimmer-effects";
import noCar from '../assets/noCar.json';
import Lottie from "lottie-react";

function Notfound({ getcardata, car_data,loading }) {

  document.body.style.backgroundColor = `#FEF200`

  return  (
    <>
     <div className="web_wrap ">
            <div className="mt-5"> <h1> Sell Any Wheel</h1> </div>
            <Lottie
            animationData={noCar} 
            // autoplay={true}
              // options={defaultOptions}
              // background="#FEF200"
              // speed="1"
              // style={{"width": "300px", "height": "300px"}}
              loop
             
              // autoplay
            ></Lottie>

            <div className="w-25 text_wrap_2"><h1 className="text_2">Vehicle is not attached</h1></div>
            <div className="w-50 mt-4">
            <p className="text_3">
              This link is not related to your request. In order to submit your photos please check your email and open that link
            </p>

            </div>
          
          </div>
    </>
  );
}

export default Notfound;
