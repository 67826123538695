import { React, useState ,useEffect,useRef} from "react";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import CameraWithOverlayVideo from "../CameraWithOverlayVideo";
import CarDamgeCamra from "../CarDamgeCamra";
import Cardamagepage from "../Cardmagepage"
import CarDamge from "../Car Damge"
import ScaleLoader from "react-spinners/ScaleLoader";

import baseUrlNew from "../BaseUrl/index"
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory,
  useParams 
} from "react-router-dom";
import { css } from "@emotion/react";
import {

  getcardata,
  } from "../Redux/reduxflow/action"
  import Lottie from "lottie-react";
  import loader from '../assets/loader.json'
  // import { Oval } from  'react-loader-spinner'


 
  const queryString = require('query-string');

function ApiCall({ userId,car_pics_data_a }) {
  const override = css`
  display: block;

`;
  var cur_data=useRef({})
  const { id } = useParams();
  // console.log("Id of user ",id)
  const [empList, setEmpList] = useState({});
  const [available, setavailable] = useState();
  const [car_type, setCartype] = useState();
  // const [res, setres] = useState(["default"]);
  const [loading, setloading] = useState(true);
  let history = useHistory();


// const [user_status, setuser_status] = useState("")


// console.log("api data in state", user_status);


let location = useLocation();
const value = queryString.parse(location.search);
useEffect(() => {

  cur_data.current=car_pics_data_a




}, [car_pics_data_a])
useEffect(() => {


  getuserStatus()
  // setres([...res," 2nd co"])


if(value.view){
  function handleEvent(message) {
    // alert("i am in handle message")
    // console.log("message from react--_>> ",message.data);
    if(window.ReactNativeWebView){
      window.ReactNativeWebView.postMessage(JSON.stringify(cur_data.current))
    }
    
  }
  document.addEventListener("message", handleEvent);

  return () =>
    document.removeEventListener("message", handleEvent);
}



}, [])









 




// console.log("local ",localStorage.getItem('user_id'))



const baseUrl =
`${baseUrlNew.baseUrl}api/v1/check_evaluation_photos/`;
// const baseUrl =
// 'http://192.168.18.21:5003/api/v1/check_evaluation_photos/';

const  getuserStatus= ()=>  {
axios
  .post(baseUrl, {
    "pk":id

  })
  .then((response) => {

    setavailable(response.data.data.available )
    setCartype(response.data.data.car_body_type )
    setloading(false)

  }).catch(({data, status, statusText, headers, config, request})=>{

  });
}


  return loading?(
    
    <>
    <center>
    <Lottie animationData={loader} style={{height:"30%",width:"30%"}} autoplay={true}/>
   
    {/* <ScaleLoader color='#FEF200' loading={true}  size={100} css={override} /> */}
    {/* <h1>Loading...</h1> */}
    </center>
    {/* <Oval color="#00BFFF" height={80} width={80} /> */}
    </>
    // available
  ) : available ? <CarDamge id={id} car_type={car_type} /> : <CameraWithOverlayVideo id={id} car_type={car_type} close={setavailable}/>;
}

const mapStateToProps = (state) => ({
  userId: state.cardata.data,
  car_pics_data_a: state.cardata.car_pics_data,
});
const mapDispatchToProps = dispatch => {
    return {
        // addapidata: (response) => dispatch(addapidata(response)),
  
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(ApiCall);
