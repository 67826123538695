import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import ReactPlayer from "react-player";
import { Camera } from "react-camera-pro";
import { hatchback, videoSteps } from "./data";
//
import baseUrlNew from "../BaseUrl/index"

import axios from "axios";
import { useHistory } from "react-router-dom";
import CarDamgeCamra from "../CarDamgeCamra";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {

  setsingleimage,
  } from "../Redux/reduxflow/action"
import DeskTopView from "../Desktopview";
import Camra from "../Camra"
import video from '../assets/hatchback-v3-motorway-400kbs-HQ.mov';
import SafeArea from 'react-safe-area-component';
import video_android from '../assets/hatchback-v3-1104x828-30fps.webm'
import Hatchback from '../assets/hatchback-v3-1104x828-30fps.webm'
import SUV from '../assets/suv-v4-motorway-400kbs-HQ.webm'
import Salon from '../assets/saloon-v3-1104x828-30fps.webm'
import HatchbackIphone from '../assets/hatchback-v3-motorway-400kbs-HQ.mov'
import SUVIphone from '../assets/suv-v4-motorway-400kbs-HQ.mov'
import SalonIphone from '../assets/saloon-v3-motorway-400kbs-HQ.mov'
import { connect, useSelector } from "react-redux";
function CameraOverlay({props,id,close,setsingleimage_a,car_type})  {


  const history = useHistory();
  // console.log("platform-------------> ",window.navigator.platform)

  const camera = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState([]);
  const [playVideo, setplayVideo] = React.useState(false);
  const [seekAmount, setseekAmount] = React.useState(1);
  const [isMobile, setisMobile] = useState(false);
  const [damagesection, setdamagesection] = useState(false);
  const [timeout, settimeout] = useState(0);
  const [video_index, setVindex] = useState(1);
  const [orientation, setorientation] = useState("portrait");
  const videoPlayer = useRef(null);
  const video_index_ref = React.useRef(0);
  const [imgename, setimgename] = useState("");
  const [carimge, setcarimge] = useState("");
  const [video_url, setvideo_url] = useState(Hatchback);
  const [temp_number, setnumber] = useState(0);
  const [sec, setsecs] = useState([0]);
  var index=1;
  const scrollInto = useRef(null)
  // console.log("car imge state",carimge)
  // console.log("car imge name  state",imgename)

  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );
  useEffect(() => {
    previousTimeRef.current=playVideo
    // if(playVideo>1){
    //   console.log("video index changed and greater then ",video_index)
      
    //   setplayVideo(true)
    // }
    
  }, [playVideo]);
  


  useEffect(() => {
    if (window.navigator.platform == "iPhone") {

      if (car_type == '1') {
        setvideo_url(HatchbackIphone)
      } else if (car_type == '2') {
          setvideo_url(SalonIphone)
      } else if ( car_type == '3') {
          setvideo_url(SalonIphone)
       }

    } else {

      if (car_type == '1') {
          setvideo_url(Hatchback)
      } else if (car_type == '2') {
          setvideo_url(Salon)
      }  else if ( car_type == '3') {
          setvideo_url(Salon)
       }
    }

    
  }, [car_type]);

 window.scrollTo(0, 1);

  const doOnOrientationChange=()=> {
    // console.log("orientation ", window.orientation);
    switch (window.orientation) {
      // case -90:
      case 90:
        setsreen("landscap");

        // console.log("landcap =========>>>>>", window.orientation);
        break;
      default:
        setsreen("portrait");
        // console.log("portrait =========>>>>", window.orientation);

        break;
    }
  }

  window.addEventListener("orientationchange", doOnOrientationChange);
  // var reqId
  // reqId = requestAnimationFrame(async function  play()  {
  //   var cur_time=await videoPlayer.current.getCurrentTime()
  //   console.log("current time--->> ",cur_time, ' --- vindex ',index)
  //   if(cur_time>=arr[index].start_at && cur_time<=arr[index].stop_at){
  //     setplayVideo(false)
  //     // console.log("Video seconds--->> ",videoPlayer.current.getCurrentTime()," --- ",arr[video_index].sec);
  //   }
    
  //   reqId = requestAnimationFrame(play);
  // });

  const requestRef = React.useRef();
  const previousTimeRef = React.useRef(false);
  
  const animate = time => {
    if (previousTimeRef.current) {
      // console.log("i am in current ref")
      // const deltaTime = time - previousTimeRef.current;
      const getCurrentTime = videoPlayer.current.getCurrentTime();
      // console.log("current time-->> ",getCurrentTime,' -- ',video_index,' ----- ',arr[video_index_ref.current])
      if(getCurrentTime>=arr[video_index_ref.current].start_at && getCurrentTime<=arr[video_index_ref.current].stop_at){
        // console.log("i am in stop video")
        setplayVideo(false)
        previousTimeRef.current=false
        // console.log("Video seconds--->> ",videoPlayer.current.getCurrentTime()," --- ",arr[video_index].sec);
      }
      
      // Pass on a function to the setter of the state
      // to make sure we always have the latest state
      // setCount(prevCount => (prevCount + deltaTime * 0.01) % 100);
    }
    
    // previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);
  useEffect(() => {
    // scrollInto.current.scrollIntoView()
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  
  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };







  // =========================api call section ==============
  const baseUrl =
  `${baseUrlNew.baseUrl}api/v1/evaluation_images/`;

  const PostCarimge=(image, data)=> {
    axios
      .post(baseUrl, {
        name: data.title,
        type: data.type,
        image_data: image,

        evaluate_image: id,
      })
      .then((response) => {
        setsingleimage_a(response.data)
        // console.log("api response", response.data);
      });
  }

  // check
  // const initialCheck = () => {
  //   const angle = window.orientation;
  //   setisMobile(navigator.userAgentData.mobile);
  //   if (angle === 90) {
  //     setorientation("landscape");
  //   } else {
  //     setorientation("portrait");
  //   }
  // };
  // useEffect(initialCheck, []);
  // window.addEventListener("orientationchange", initialCheck);
  //
  // console.log("device orientation", orientation);
  // console.log("device props", props);
  // console.log("device isMobileprops", isMobile);
  // console.log("device orientation", orientation);
  // console.log("device navigator.userAgent", navigator);
  // console.log("device navigator.userAgent", navigator.userAgent);
  // console.log("device navigator.userAgentData", navigator.userAgentData.mobile);
  // console.log("device window.matchMedia", window.matchMedia);
  // const capture = React.useCallback(() => {
  //   const imageSrc = camera .current.getScreenshot();
  //   setImgSrc([...imgSrc, imageSrc]);
  //   console.log("imgSrc", imgSrc);
  //   // setImgSrc(imageSrc);
  // }, [camera , setImgSrc]);
  // const setplayVideoHandler = (sec = 0) => {
  //   // console.log("captureHandler setplayVideoHandler sec", sec);
    
  //   const timeoutID =window.setTimeout(() => {
  //     window.clearTimeout(timeout );
  //     setplayVideo(false);
  //   }, sec * 1000);
  //   setplayVideo(true);
  //   settimeout(timeoutID)
  // };
  //
  //
  const arr = [].concat.apply([], Object.values(videoSteps));
  const captureHandler = () => {

    
    // console.log("this is the actuala time to use ",videoPlayer.current.getCurrentTime())
    const imageSrc = camera.current.takePhoto();

    PostCarimge(imageSrc,arr[video_index_ref.current])

    // const newImgSrc = [...imgSrc, imageSrc];
    // const videoStep = videoSteps[newImgSrc.length];
   
    // console.log("arr==>> ", arr);
 
   
    // console.log("video inde ",video_index)
    // console.log("videoStep==>> ", videoStep);
  // console.log("length ====> ",arr.length," video_index ",video_index)
  if((video_index_ref.current+1)==arr.length){
    setdamagesection(true)
    // console.log("end if it")
  }
  else{
    // const videoStep = arr[video_index];
    // setplayVideoHandler(videoStep.sec);
    // index=index+1
    video_index_ref.current=video_index_ref.current+1
    // var v_=video_index
    // setVindex(video_index_ref.current)
    setplayVideo(true)
    
  }
  
    // if (videoStep.type === "reset") {
    //   console.log("captureHandler videoStep if reset", videoStep);
    //   setplayVideoHandler(videoStep.sec);
    //   console.log("go camra page ")


    // } else {
    //   // if (arr.length !== imgSrc.length) {
    //     // console.log("captureHandler videoStep else", videoStep);
    //     setplayVideoHandler(videoStep.sec);
    //     // setImgSrc(imageSrc);
    //   // }
    //   // else{
    //     // history.push("/camra")
    //   // }
    // }
   
  };
  
  const videoPlayerHandler = (e) => {
    //   const [temp_number, setnumber] = useState(0)
    // const [sec,setsecs]=useState([0])
    // const cur = temp_number;
    // console.log("Temp Number ", temp_number);

    // const videoPlayer_ = videoPlayer.current;
    // setplayVideo(true);
    // setplayVideo(!playVideo);
    const getCurrentTime = videoPlayer.current.getCurrentTime();
    console.log("current time-->> ",getCurrentTime)
    if(getCurrentTime>=arr[index].start_at && getCurrentTime<=arr[index].stop_at){
      setplayVideo(false)
      // console.log("Video seconds--->> ",videoPlayer.current.getCurrentTime()," --- ",arr[video_index].sec);
    }
    // setsecs([...sec, getCurrentTime - cur]);
    // setnumber(getCurrentTime);

    // console.log(sec);
    // console.log("videoPlayerHandler getCurrentTime", getCurrentTime);
  };
  return damagesection? <CarDamgeCamra id ={id} close={close}/>:(
    <>
    
      {/* <meta name="viewport" content="width=1024, minimal-ui"/> */}
      {/* <meta name="viewport" content = "height=device-height, width = device-width, initial-scale = 1.0, minimum-scale = 1, maximum-scale = 1, " /> */}
    
    
    {/* <meta name="apple-mobile-web-app-title" content="Capture Car Pics" />
    
    <meta name="apple-mobile-web-app-capable" content="yes"/>
    
    <meta name="apple-mobile-web-app-status-bar-style" content="black" />
    <meta name="mobile-web-app-capable" content="yes"/> */}
      <div className="container-fluied aaa">
     
        <BrowserView>
          <DeskTopView />
        </BrowserView>

        <MobileView>
          {sreen == "landscap" ? (
            <div className="container_">
              <div className="camera_main_cont">
                <div className="camera_wrapper">
                  <Camera
                    facingMode="environment"
                    width="100%"
                    height="100%"
                    ref={camera}
                  />
                  <div className="video_wrapper">
                    <ReactPlayer
                      ref={videoPlayer}
                      playing={playVideo}
                      // playing={true}/
                      width="90%"
                      height="120%"
                      playsinline 
                      // onProgress={(e) => videoPlayerHandler(e)}
                      // onSeek={(e) => console.log("react player onseek", e)}
                      url={video_url}
                      // url={window.navigator.platform=="iPhone"?F:video_android}
                      // url="https://firebasestorage.googleapis.com/v0/b/auction-85e6c.appspot.com/o/suv-v4-30fps-1104x828.webm?alt=media&token=61a99d4c-6de4-45a3-9803-49ea470efa41"
                      // url="https://firebasestorage.googleapis.com/v0/b/auction-85e6c.appspot.com/o/saloon-v3-1104x828-30fps%20(1).webm?alt=media&token=c060073c-1055-48af-83f2-3653b20be68a"
                    />
                  </div>
                </div>
                <div ref={scrollInto} className="bottom_title_bar flex_center">
                  <div>{`${video_index_ref.current+1} /${arr.length } ${
                    arr[video_index_ref.current].title
                  }`}</div>
                </div>
                <div className="tool_bar">
                  {!playVideo && (
                    <button
                      className="round_button flex_center"
                      onClick={captureHandler}
                    >
                      <div className="round_button_ flex_center" />
                    </button>
                  )}
                  {/* <button onClick={videoPlayerHandler}>play/pause</button> */}
                </div>
              </div>
              {/* <div className="camera_wrapper">
        <Camera
          facingMode="environment"
          width="100%"
          height="100%"
          ref={camera}
        />
        <div className="video_wrapper">
          <ReactPlayer
            playing={playVideo}
            // playing={true}
            width="100%"
            height="100%"
            onSeek={(e) => console.log("react player onseek", e)}
            url="https://firebasestorage.googleapis.com/v0/b/auction-85e6c.appspot.com/o/hatchback-v3-1104x828-30fps.webm?alt=media&token=19652017-9e8e-4b68-8175-462ed5aeaede"
          />
        </div>
        <div className="tool_bar">
          <button onClick={captureHandler}>Capture photo</button>
          <button onClick={capture}>Capture photo</button>
          <button onClick={() => setplayVideo(!playVideo)}>play/pause</button>
          <button onClick={setplayVideoHandler}>play/pause</button>
        </div>
      </div> */}
            </div>
          ) : (
            <div className="Lotie_Main_Wrap">
              <div className="lotie_wrap">
                <lottie-player
                  src="https://assets10.lottiefiles.com/private_files/lf30_uqnbeq1u.json"
                  background="#48BA7E"
                  speed="1"
                  style={{ height: "92vh" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>
          )}
        </MobileView>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  userId: state.cardata.data,
});
const mapDispatchToProps = dispatch => {
    return {
      setsingleimage_a: (response) => dispatch(setsingleimage(response)),
  
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(CameraOverlay);
