
// this is the actuala time to use  0
// index.js:163 this is the actuala time to use  3.788604
// index.js:163 this is the actuala time to use  8.212244
// index.js:163 this is the actuala time to use  12.299489
// index.js:163 this is the actuala time to use  16.559515
// index.js:163 this is the actuala time to use  20.012292
// index.js:163 this is the actuala time to use  24.84667
// index.js:163 this is the actuala time to use  27.828262
// index.js:163 this is the actuala time to use  32.492625
// index.js:163 this is the actuala time to use  35.756166
// index.js:163 this is the actuala time to use  39.155923
// index.js:163 this is the actuala time to use  42.130944
// index.js:163 this is the actuala time to use  48.117185
// index.js:163 this is the actuala time to use  55.127016
// index.js:163 this is the actuala time to use  59.215736
// index.js:163 this is the actuala time to use  64.89539
export const videoSteps = [
  {
    id: 1,
    time: "00:00",
    sec: 0,
    start_at:0,
    stop_at:0,
    title: "Front Driver Corner",
    type: "exterior",
  },
  {
    id: 2,
    time: "00:03.725684",
    sec: 3.788604,
    start_at:3.400,
    stop_at:3.788604,
    title: "Rear Driver Corner",
    type: "exterior",
  },
  {
    id: 3,
    time: "00:08.061032",
    sec: 4.335348,
    start_at:7.900,
    stop_at:8.212244,
    title: "Rear Passenger Corner",
    type: "exterior",
  },
  {
    id: 4,
    time: "00:12.220431",
    sec: 4.159399,
    start_at:11.901,
    stop_at:12.299489,
    title: "Front Passenger Corner",
    type: "exterior",
  },
  {
    id: 5,
    time: "00:16.436003",
    sec: 4.215572,
    start_at:16.402,
    stop_at:16.559515,
    title: "Front Passenger Alloys",
    type: "Wheels",
  },
  {
    id: 6,
    time: "00:19.815601",
    sec:  3.379598,
    start_at:19.65,
    stop_at:20.012292,
    title: "Front Passenger Tyre Tread",
    type: "treads",
    
  },
  {
    id: 7,
    time: "00:24.484116",
    sec:  4.668515,
    start_at:24.151,
    stop_at:24.84667,
    title: "Front Driver Alloys",
    type: "Wheels",
  },
  {
    id: 8,
    time: "00:27.82046",
    sec:  3.336344,
    start_at:27.650,
    stop_at:27.828262,
    title: "Front Driver Tyre Tread",
    type: "treads",
  },
  {
    id: 9,
    time: "00:32.556962",
    sec: 4.736502,
    start_at:32.152,
    stop_at:32.492625,
    title: "Rear Driver Alloys",
    type: "Wheels",
  },
  {
    id: 10,
    time: "00:35.836036",
    sec:  3.279074,
    start_at:35.401,
    stop_at:35.756166,
    title: "Rear Driver Tyre Tread",
    type: "treads",
  },
  {
    id: 11,
    time: "00:38.996584",
    sec:  3.160548,
    start_at:38.901,
    stop_at:39.155923,
    title: "Rear Passenger Alloys",
    type: "Wheels",
  },
  {
    id: 12,
    time: "00:42.104007",
    sec: 3.107423,
    start_at:41.902,
    stop_at:42.130944,
    title: "Rear Passenger Tyre Tread",
    type: "treads",
  },
  {
    id: 13,
    time: "00:48.308285",
    sec: 6.204278,
    start_at:47.902,
    stop_at:48.117185,
    title: "Boot Interior",
    type: "interior",
  },
  {
    id: 14,
    time: "00:54.880192",
    sec: 6.571907,
    start_at:54.901,
    stop_at:55.127016,
    title: "Back Seats",
    type: "interior",
  },
  {
    id: 15,
    time: "00:59.075932",
    sec: 4.19574,
    start_at:58.902,
    stop_at:59.215736,
    title: "Dashboard",
    type: "interior",
  },
  {
    id: 16,
    time: "01:04.733849",
    sec:5.657917,
    start_at:64.402,
    stop_at:64.88539,
    title: "Front Seats",
    type: "interior",
  },
  // {
  //   id: 17,
  //   time: "01:07",
  //   sec: 2.266151,
  //   title: "Finish",
  //   type: "reset",
  // },

];
export const suv = {
  exterior: [
    {
      id: "EXT1",
      time: "00:00",
      sec: 0,
      title: "Front Driver Corner",
      type: "exterior",
    },
    {
      id: "EXT2",
      time: "00:03.725684",
      sec: 3.725684,
      title: "Rear Driver Corner",
      type: "exterior",
    },
    {
      id: "EXT3",
      time: "00:08.061032",
      sec: 4.335348,
      title: "Rear Passenger Corner",
      type: "exterior",
    },
    {
      id: "EXT4",
      time: "00:12.220431",
      sec: 4.159399,
      title: "Front Passenger Corner",
      type: "exterior",
    },
  ],
  wheels: [
    {
      id: "WHE1",
      time: "00:16.436003",
      sec: 4.215572,
      title: "Front Passenger Alloys",
      type: "Wheels",
    },
    {
      id: "WHE2",
      time: "00:19.815601",
      sec: 3.379598,
      title: "Front Driver Alloys",
      type: "Wheels",
    },
    {
      id: "WHE3",
      time: "00:24.484116",
      sec: 4.668515,
      title: "Rear Driver Alloys",
    },
    {
      id: "WHE4",
      time: "00:27.82046",
      sec: 3.336344,
      title: "Rear Passenger Alloys",
      type: "Wheels",
    },
  ],
  treads: [
    {
      id: "TRE1",
      time: "00:32.556962",
      sec: 4.736502,
      title: "Front Passenger Tyre Tread",
      type: "treads",
    },
    {
      id: "TRE2",
      time: "00:35.836036",
      sec: 3.279074,
      title: "Front Driver Tyre Tread",
      type: "treads",
    },
    {
      id: "TRE3",
      time: "00:38.996584",
      sec: 3.160548,
      title: "Rear Driver Tyre Tread",
      type: "treads",
    },
    {
      id: "TRE4",
      time: "00:42.104007",
      sec: 3.107423,
      title: "Rear Passenger Tyre Tread",
      type: "treads",
    },
  ],
  interior: [
    {
      id: "INT1",
      time: "00:48.308285",
      sec: 6.204278,
      title: "Boot Interior",
      type: "interior",
    },
    {
      id: "INT2",
      time: "00:54.880192",
      sec: 6.571907,
      title: "Back Seats",
      type: "interior",
    },
    {
      id: "INT3",
      time: "00:59.075932",
      sec: 4.19574,
      title: "Dashboard",
      type: "interior",
    },
    {
      id: "INT4",
      time: "01:04.733849",
      sec: 5.657917,
      title: "Front Seats",
      type: "interior",
    },
  ],
};
//
export const hatchback = {
  exterior: [
    {
      id: "EXT1",
      time: "00:00",
      sec: 0,
      title: "Front Driver Corner",
      type: "exterior",
    },
    {
      id: "EXT2",
      time: "00:03.725684",
      sec: 3.725684,
      title: "Rear Driver Corner",
      type: "exterior",
    },
    {
      id: "EXT3",
      time: "00:08.061032",
      sec: 4.335348,
      title: "Rear Passenger Corner",
      type: "exterior",
    },
    {
      id: "EXT4",
      time: "00:12.220431",
      sec: 4.159399,
      title: "Front Passenger Corner",
      type: "exterior",
    },
  ],
  wheels: [
    {
      id: "WHE1",
      time: "00:16.436003",
      sec: 4.215572,
      title: "Front Passenger Alloys",
      type: "Wheels",
    },
    {
      id: "WHE2",
      time: "00:19.815601",
      sec: 3.379598,
      title: "Front Driver Alloys",
      type: "Wheels",
    },
    {
      id: "WHE3",
      time: "00:24.484116",
      sec: 4.668515,
      title: "Rear Driver Alloys",
    },
    {
      id: "WHE4",
      time: "00:27.82046",
      sec: 3.336344,
      title: "Rear Passenger Alloys",
      type: "Wheels",
    },
  ],
  treads: [
    {
      id: "TRE1",
      time: "00:32.556962",
      sec: 4.736502,
      title: "Front Passenger Tyre Tread",
      type: "treads",
    },
    {
      id: "TRE2",
      time: "00:35.836036",
      sec: 3.279074,
      title: "Front Driver Tyre Tread",
      type: "treads",
    },
    {
      id: "TRE3",
      time: "00:38.996584",
      sec: 3.160548,
      title: "Rear Passenger Tyre Tread",
      type: "treads",
    },
    {
      id: "TRE4",
      time: "00:42.104007",
      sec: 3.107423,
      title: "Rear Driver Tyre Tread",
      type: "treads",
    },
  ],
  interior: [
    {
      id: "INT1",
      time: "00:48.308285",
      sec: 6.204278,
      title: "Boot Interior",
      type: "interior",
    },
    {
      id: "INT2",
      time: "00:54.880192",
      sec: 6.571907,
      title: "Back Seats",
      type: "interior",
    },
    {
      id: "INT3",
      time: "00:59.075932",
      sec: 4.19574,
      title: "Dashboard",
      type: "interior",
    },
    {
      id: "INT4",
      time: "01:04.733849",
      sec: 5.657917,
      title: "Front Seats",
      type: "interior",
    },
  ],
};
