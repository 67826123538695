
    import { combineReducers } from 'redux';


    import counterReducer from './reduxflow/reducer';


    const rootReducer = combineReducers({

        cardata: counterReducer,

    });

    export default rootReducer;