import React from "react";
import CameraWithOverlayVideo from "./CameraWithOverlayVideo";
import CarDamgeCamra from "./CarDamgeCamra";
import Cardamagepage from "./Cardmagepage"
import CarDamge from "./Car Damge"
import ApiCall from "./ApiCall";
import Camra from "./Camra"
import Notfound from "./Blank"
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { BrowserRouter as Router, Switch, Route, Link,  useLocation,
  useHistory, } from "react-router-dom";

 
const queryString = require('query-string');
const App = () => {

  return (
    <>
      {/* <CameraWithOverlayVideo /> */}

      <Router>
        <div>
          {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            {/* <Route path="/camrawithvideo">
              <CameraWithOverlayVideo />
            </Route>
            <Route path="/cardamagepage">
              <Cardamagepage />
            </Route>
        
           
           
            <Route path="/apicall">
              <ApiCall />
            </Route>
            <Route path="/camra">
              <Camra />
            </Route>


            <Route path="/cardamagecamra">
              <CarDamgeCamra/>
            </Route>
            <Route path="/cardamage">
              <CarDamge/>
            </Route> */}
            <Route path="/:id" component={ApiCall} />
            <Route path="/" component={Notfound} />
            {/* <Route path="/:id">
            <ApiCall userId={match.params.id} />
            </Route> */}
          </Switch>
        </div>
      </Router>
    </>
  );
};

export default App;
