
    import { ADD_CAR_DATA,UPDATE_CAR_DATA,UPDATE_IMAGES_,ADD_CAR_DATA_MAIN,ADD_DAMAGE_DATA_MAIN } from './types';

    const name_to_target={
      "exterior":"exterior_images_",
      'Wheels':'wheels_images_',
      'treads':'treads_images_',
      'interior':"interior_images_"
    }
    const INITIAL_STATE = {

        car_pics_data: {
          "exterior_images_":[],
          "wheels_images_":[],
          "treads_images_":[],
          "interior_images_":[],
          "damage_images_":[]
        },
        exterior:[],
        interior:[],
        treads:[],
        wheels:[],
        loading:true,
    };

    const reducer = (state = INITIAL_STATE, action) => {

        switch (action.type) {
            case "SUBMIT_SINGLE_IMAGE":
            // console.log("xxxxxxxxxx",action)
              var d_=state.car_pics_data
              if(d_[name_to_target[action.payload.data.type]]){
                d_[name_to_target[action.payload.data.type]].push(action.payload.data)
              }
              else{
                d_[name_to_target[action.payload.data.type]] = action.payload.data
              }

              return {

                ...state,  
                  car_pics_data:d_,
                // loading:false,
              };
            case ADD_CAR_DATA:
// console.log("xxxxxxxxxx",action)
               return {

                 ...state,  
                  car_pics_data:action.payload.data,
                 loading:false,
               };
            case ADD_DAMAGE_DATA_MAIN:
                // console.log("xxxxxxxxxx",action)
              var d_=state.car_pics_data
              
              d_["damage_images_"].push(action.payload.data)
              return {

                ...state,  
                car_pics_data:d_,
                loading:false,
                              };
            case ADD_CAR_DATA_MAIN:
                // console.log("xxxxxxxxxx",action)
                var d=state.car_pics_data
                
                d[action.payload.data.type.toLowerCase()+"_images_"].push(action.payload.data)
                return {

                  ...state,  
                  car_pics_data:d,
                  loading:false,
                               };
            case UPDATE_CAR_DATA:

                var key=action.payload.data['key']
                
                state.car_pics_data[key][action.index]=action.payload.data
               return {

                 ...state,car_pics_data:state.car_pics_data,

                //  newArry=car_pics_data[]

                 loading:false,
               };


               case UPDATE_IMAGES_:
                // console.log("UPDATE_IMAGES_UPDATE_IMAGES_",state.car_pics_data)

            

   
                               return {
                
                                 ...state,  
                                  car_pics_data:action.payload.data,
                                 loading:false,
                               };


           

             default: return state;

        }

    };

    export default reducer;