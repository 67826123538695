import React , {  useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import { useState, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  MdOutlineModeEditOutline,
  MdOutlineDirectionsCar,
} from "react-icons/md";
import "./style.css";
import { GrAddCircle } from "react-icons/gr";
import DeskTopView from "../Desktopview";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import baseUrlNew from "../BaseUrl/index"
import CarDamgeCamra from "../CarDamgeCamra";
import { getcardata } from "../Redux/reduxflow/action";
import camra from "../Camra"
import Camra2 from "../Camra2"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Camra from "../Camra"
import { ShimmerPostList,ShimmerThumbnail  } from "react-shimmer-effects";
function CarDamage({ getcardata, car_data,loading,id ,close,car_type}) {
  

 const location = useLocation();
const queryString = require("query-string");

const value = queryString.parse(location.search);

// console.log("valuevaluevalue=======", value.view);











  const history = useHistory();
  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );

  var exterior =[ "Front Driver Corner","Rear Driver Corner","Rear Passenger Corner","Front Passenger Corner",
  ];

  var wheels=["Front Passenger Alloys","Front Driver Alloys","Rear Passenger Alloys","Rear Driver Alloys",
   
  ];
  var treads= ["Front Passenger Tyre Tread","Front Driver Tyre Tread","Rear Driver Tyre Tread","Rear Passenger Tyre Tread",
   
  ];
  var interior=["Boot Interior","Back Seats","Dashboard","Front Seats",
    
  ];
  var title_to_index={
    "Front Driver Corner":0,
    "Rear Driver Corner":1,
    "Rear Passenger Corner":2,
    "Front Passenger Corner":3,
    "Front Passenger Alloys":4,
    "Front Passenger Tyre Tread":5,
    "Front Driver Alloys":6,
    "Front Driver Tyre Tread":7,
    "Rear Driver Alloys":8,
    "Rear Driver Tyre Tread":9,
    "Rear Passenger Alloys":10,
    "Rear Passenger Tyre Tread":11,
    "Boot Interior":12,
    "Back Seats":13,
    "Dashboard":14,
    "Front Seats":15,
  }

  //  var exterior =[
  //   {
  //     title: "Front Driver Corner",
  //     type: "exterior",
  //   },
  //   {
  //     title: "Rear Driver Corner",
  //     type: "exterior",
  //   },
  //   {
  //     title: "Rear Passenger Corner",
  //     type: "exterior",
  //   },
  //   {
     
  //     title: "Front Passenger Corner",
  //     type: "exterior",
  //   },
  // ];
  
  // var wheels=[
  //   {
  
  //     title: "Front Passenger Alloys",
  //     type: "Wheels",
  //   },
  //   {
   
  //     title: "Front Driver Alloys",
  //     type: "Wheels",
  //   },
  //   {
     
  //     title: "Rear Driver Alloys",
  //     type: "Wheels",
  //   },
  //   {
      
  //     title: "Rear Passenger Alloys",
  //     type: "Wheels",
  //   },
  // ];
  // var treads= [
  //   {
    
  //     title: "Front Passenger Tyre Tread",

  //   },
  //   {
    
  //     title: "Front Driver Tyre Tread",
  //     type: "treads",
  //   },
  //   {
      
  //     title: "Rear Driver Tyre Tread",
  //     type: "treads",
  //   },
  //   {
     
  //     title: "Rear Passenger Tyre Tread",
  //     type: "treads",
  //   },
  // ];
  // var interior=[
  //   {

  //     title: "Boot Interior",
  //     type: "interior",
  //   },
  //   {
     
  //     title: "Back Seats",
  //     type: "interior",
  //   },
  //   {
     
  //     title: "Dashboard",
  //     type: "interior",
  //   },
  //   {

  //     title: "Front Seats",
  //     type: "interior",
  //   },
  // ];
  












  // console.log("chk car data in redux ======>>>", car_data,"======",car_data.exterior_images_);

  useEffect(() => {
    // if(car_data.length > 0)
    // {
      PostCarimge();
    // }
   
  }, []);

  const doOnOrientationChange=()=> {
    // console.log("orientation ", window.orientation);
    switch (window.orientation) {
      // case -90:
      case 90:
        setsreen("landscap");

        // console.log("landcap =========>>>>>", window.orientation);
        break;
      default:
        setsreen("portrait");
        // console.log("portrait =========>>>>", window.orientation);/

        break;
    }
  }

  window.addEventListener("orientationchange", doOnOrientationChange);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isdamageOpen, setIsDamageOpen] = useState(false);
  const [recapture, setreCapture] = useState(false);
  const [item_damage, setItem] = useState({});
  const images = ["http://placeimg.com/1200/800/nature"];

  const [imge, setImge] = useState("http://placeimg.com/1200/800/nature");
// console.log(currentImage,"wwwwwww",isViewerOpen)

const [image_id, setimage_id] = useState("")

  const openImageViewer = useCallback((data,index,item,) => {
    if(!data.key){
      // if(!key){
        if(data.type=="Wheels"){
          data.key="wheels_images"
        }
        else if(data.type=="treads"){
          data.key="treads_images_"
        }
        else if(data.type=="interior"){
          data.key="interior_images"
        }
        else if(data.type=="exterior"){
          data.key="exterior_images_"
        }
      // }
    }

    setimge_categary(data.name);
    setCurrentImage(index);
    setimage_id(data)
    setImge(data.image)
    setIsViewerOpen(true);
    // setImge("http://placeimg.com/1200/900/nature")
  }, []);


const damge_function=(item,index)=>{
  setIsDamageOpen(true);
  // console.log("damage ",isdamageOpen)
  setCurrentImage(index);
  setImge(item.image)
  setItem(item);
  // var ship = document.getElementById('ship')
  // console.log("element ",ship)
  // console.log("Item ",item)
  // ship.style.left=item.left
  // ship.style.top=item.top
  // ship.style.height=item.height
  // ship.style.width=item.width
  
  // console.log("damage ",isdamageOpen)
}



const closeDamage=()=>{
  setIsDamageOpen(false)
}

  const closeImageViewer = () => {
   
    setIsViewerOpen(false);
  };

  // ==============add car damge pic ============
  const take_imge = (index) => {
// console.log("take image--->> ",index)
// console.log(image_id,"get pic imge index",index)
setreCapture(true);
// history.push({ pathname: "/camra", state: { image_id,index} });
  };


// ==========================add extirer=====================

const [camra_open, setcamra_open] = useState(false)






  // ========================api calll ===============

  const baseUrl =
    `${baseUrlNew.baseUrl}api/v1/evaluation_images_Details/${id}`;
  const PostCarimge=()=> {
    axios.get(baseUrl).then((response) => {
      getcardata(response.data);
      // console.log("api response", response.data);
    });
  }

const [damgecamra, setdamgecamra] = useState(true)


const [img_type, setimg_type] = useState()
const [imge_categary, setimge_categary] = useState()
const add_extrier=(current,type)=>{

  setimg_type(type)
  setimge_categary(current)
  // console.log("currentcurrent",type,current)
  setcamra_open(true)

}

const add_damege_pic=()=>{
  setdamgecamra(false)
}


if(!damgecamra){
  return(
    <CarDamgeCamra id ={id} close={setdamgecamra}    />
  )
 

}

if(camra_open){
  return(
    <Camra2 car_type={car_type} setcamra_open={setcamra_open}   img_type={img_type} imge_categary={imge_categary} index_of_data={title_to_index[imge_categary]}  />
  )
 

}


const continue_handeler=()=>{


}






const get_view=(car_data_api,current,type)=>{
  var flag=false
  var index=-1
  for(var i =0;i<car_data_api.length;i++){
    if(car_data_api[i].name==current){
      flag=true
      index=i
      break
    }
  }
  return flag?(<div>
  <span
    className="edit_icon"
    onClick={() => openImageViewer(car_data_api[index],index,type)}
    // onClick={() => openImageViewer(index)}
  >
    <MdOutlineModeEditOutline />
  </span>
  <img
    src={car_data_api[index].image}
    onClick={() => openImageViewer(car_data_api[index],index,type)}
    className="damage_img"
    key={index}
    //   style={{ margin: "2px" }}
    alt=""
  />
</div>):(<div className="damage_img_" onClick={()=>{add_extrier(current,type)} } >
                        
                        
                        <div className="d-flex" >
            
                 <GrAddCircle size={25}  color="blue"   />
                 <h5 className="ml-2"   >Add</h5>
                 </div>
                        
                      
                      </div>)
}








  return (
    <>
      <BrowserView>
        <DeskTopView />
      </BrowserView>
      <MobileView>
        {sreen == "landscap" ? (
          recapture ? (
            <>
              <Camra
                 car_type={car_type}
                data={image_id}
                index={currentImage}
                index_of_data={title_to_index[imge_categary]}
                state={setreCapture}
                view={setIsViewerOpen}
              />
            </>
          ) : isdamageOpen ? (
            <>
              <div>
                {/* <button  onClick={() => take_imge(currentImage)}   className="retake_btn">take</button> */}
                <button onClick={closeDamage} className="cross">
                  X
                </button>
                <div
                  className="buble"
                  id="ship"
                  style={{
                    top: item_damage.top,
                    left: item_damage.left,
                    width: item_damage.width,
                    height: item_damage.height,
                  }}
                ></div>
                <img
                  src={imge}
                  currentindex={currentImage}
                  style={{
                    width: "100%",
                    height: "20rem",
                    // height:"70%",
                    // width:"100%",

                    // backgroundColor: "rgba(0,0,0,0.9)"
                  }}
                />
              </div>
            </>
          ) : isViewerOpen ? (
            <>
              <div>
                <button
                  onClick={() => take_imge(currentImage)}
                  className="retake_btn"
                >
                  Re-Capture
                </button>
                <button onClick={closeImageViewer} className="cross">
                  ✕
                </button>
                <img
                  src={imge}
                  currentindex={currentImage}
                  style={{
                    height: "100%",
                    width: "100%",

                    // backgroundColor: "rgba(0,0,0,0.9)"
                  }}
                />
              </div>
            </>
          ) : loading ? (
            <div className="Dmage_wrap p-5">
              <ShimmerThumbnail height={50} width={200} rounded />
              <div className="Dmage_inside">
                {React.Children.toArray(
                  [1, 2, 1, 2, 1, 2, 1, 2].map((item, index) => (
                    <div className="p-2 ">
                      <div className="imge_wrap">
                        <div className="damage_img">
                          <ShimmerThumbnail rounded />
                        </div>
                      </div>

                      <span className="mt-5 p-2">
                        {" "}
                        <ShimmerThumbnail
                          width={150}
                          height={30}
                          rounded
                        />{" "}
                      </span>
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : (
            //         <div className="p-5">
            // <ShimmerThumbnail height={250} rounded />
            //         </div>
            <>
              <div className="container mt-5">
                <h2 className="text-center"> Review your photos</h2>

                <div className="Dmage_wrap">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="mr-3">Exterior</h3>
                  </div>

                  <div className="Dmage_inside">
                    {React.Children.toArray(
                      exterior.map((item, index) => (
                        <div className="p-2 ">
                          <div className="imge_wrap">
                            {
                              get_view(
                                car_data.exterior_images_,
                                item,
                                "exterior"
                              )
                              // car_data.exterior_images_.filter((item_)=>item_.name==item)
                            }
                            {/* <div>
                            <span
                              className="edit_icon"
                              onClick={() => openImageViewer(item,index)}
                              // onClick={() => openImageViewer(index)}
                            >
                              <MdOutlineModeEditOutline />
                            </span>
                            <img
                              src={item.image}
                              onClick={() => openImageViewer(item,index)}
                              className="damage_img"
                              key={index}
                              //   style={{ margin: "2px" }}
                              alt=""
                            />
                          </div> */}
                          </div>

                          <span>{item} </span>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                <div className="Dmage_wrap">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="mr-3">Interior</h3>
                  </div>
                  <div className="Dmage_inside">
                    {React.Children.toArray(
                      interior.map((item, index) => (
                        <div className="p-2 ">
                          <div className="imge_wrap">
                            {get_view(
                              car_data.interior_images_,
                              item,
                              "interior"
                            )}
                          </div>

                          <span>{item} </span>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                <div className="Dmage_wrap">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="mr-3">Treads</h3>
                  </div>
                  <div className="Dmage_inside">
                    {React.Children.toArray(
                      treads.map((item, index) => (
                        <div className="p-2 ">
                          <div className="imge_wrap">
                            {get_view(car_data.treads_images_, item, "treads")}
                          </div>

                          <span>{item} </span>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                <div className="Dmage_wrap ">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="mr-3">Wheels</h3>
                  </div>

                  <div className="Dmage_inside">
                    {React.Children.toArray(
                      wheels.map((item, index) => (
                        <div className="p-2 ">
                          <div className="imge_wrap">
                            {get_view(car_data.wheels_images_, item, "Wheels")}
                          </div>

                          <span>{item} </span>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                <div className="Dmage_wrap">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="mr-3">Damge</h3>
                    <div className="d-flex " onClick={add_damege_pic}>
                      <GrAddCircle size={25} color="blue" />
                      <h5 className="ml-2">Add Damge</h5>
                    </div>
                  </div>

                  <div className="Dmage_inside">
                    {React.Children.toArray(
                      car_data.damage_images_.map((item, index) => (
                        <div className="p-2 ">
                          <div className="imge_wrap">
                            <div>
                              <img
                                src={item.image}
                                onClick={() => damge_function(item, index)}
                                className="damage_img"
                                key={index}
                                //   style={{ margin: "2px" }}
                                alt=""
                              />
                            </div>
                          </div>

                          <span>{item.name} </span>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                {!value.view ? (
                  <div className="contuine_">
                    <button className="btn-con">
                      <a
                        className="a-"
                        target="black"
                        href={`https://sellanywheel.com/carsteps/${id}`}
                      >
                        Move to Next Step
                      </a>
                    </button>
                  </div>
                ) : null}
              </div>
            </>
          )
        ) : (
          <div className="Lotie_Main_Wrap">
            <div className="lotie_wrap">
              <lottie-player
                src="https://assets10.lottiefiles.com/private_files/lf30_uqnbeq1u.json"
                background="#48BA7E"
                speed="1"
                style={{ height: "92vh" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
        )}
      </MobileView>
    </>
  );
}

const mapStateToProps = (state) => ({
  car_data: state.cardata.car_pics_data,
  loading: state.cardata.loading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getcardata: (response) => dispatch(getcardata(response)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CarDamage);
