export default {
    // baseUrl: "http://192.168.18.21:7000/",
    // baseUrl: "https://ec2-18-116-175-124.us-east-2.compute.amazonaws.com:8005/",



// ==============  new base urrl ============
    // baseUrl: "http://192.168.18.11:8000/",
    baseUrl: "https://backend.sellanywheel.com/",
  
    // baseUrl: "https://backend.sellanywheel.com:7000/",
  
  };