import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import ReactPlayer from "react-player";
import { Camera } from "react-camera-pro";
import { hatchback, videoSteps } from "./data";
//
import DeskTopView from "../Desktopview"
import baseUrlNew from "../BaseUrl/index"

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Cardamagepage from "../Cardmagepage"
import { useHistory } from "react-router-dom";
export default ({props,id,close,setdamgecamra}) => {




  const camera = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState([]);
  const [playVideo, setplayVideo] = React.useState(false);
  const [seekAmount, setseekAmount] = React.useState(1);
  const [isMobile, setisMobile] = useState(false);
  const [showdamagecolor, setshowdamagecolor] = useState(false);
  const [img, setimg] = useState("");
  const [orientation, setorientation] = useState("portrait");
  const videoPlayer = useRef(null);
  // check
  const [image, setImage] = useState(null);


  let history = useHistory();


  const [sreen, setsreen] = useState(
    window.orientation == 90 ? "landscap" : "portrait"
  );

  const doOnOrientationChange=()=> {
    // console.log("orientation ", window.orientation);
    switch (window.orientation) {
      // case -90:
      case 90:
        setsreen("landscap");

        // console.log("landcap =========>>>>>", window.orientation);
        break;
      default:
        setsreen("portrait");
        // console.log("portrait =========>>>>", window.orientation);

        break;
    }
  }

  window.addEventListener("orientationchange", doOnOrientationChange);






const done_close=()=>{
  // setdamgecamra(false)
close(true)

}
  // const initialCheck = () => {
  //   const angle = window.orientation;
  //   setisMobile(navigator.userAgentData.mobile);
  //   if (angle === 90) {
  //     setorientation("landscape");
  //   } else {
  //     setorientation("portrait");
  //   }
  // };
  // useEffect(initialCheck, []);
  // window.onorientationchange = initialCheck;
  

  //
  //
  const Uploadpic = (data) => {

    // console.log("qqqqqq",data)
    setimg(data)
    setshowdamagecolor(true)

    // history.push({ pathname: "/cardamagepage", state: {data } });
    
  };

  return showdamagecolor?   <Cardamagepage id={id} image={img} close={setshowdamagecolor}/>:(


<>


<div className="container-fluied aaa">
        <BrowserView>

        <DeskTopView/>

        
        </BrowserView>

        <MobileView>
          {sreen == "landscap" ? (
           
    <div className="container_">
     <button  onClick={() => done_close()}   className="retake_btn">Exit</button>
      <div className="camera_main_cont">
        <div className="camera_wrapper">
          <Camera
            facingMode="environment"
            width="100%"
            height="100%"
            ref={camera}
          />
          <div className="video_wrapper">
          <div className="bottom_title_bar flex_center w-100">
                  <div>Take Images of damage on your car. You can Exit if your car do not have any damage.</div>
                </div>
          </div>
        </div>
     
        <div className="tool_bar">
         
            <button
              className="round_button flex_center"
              onClick={() => Uploadpic(camera.current.takePhoto())}
            >
              <div className="round_button_ flex_center" />
            </button>
 
          {/* <button onClick={videoPlayerHandler}>play/pause</button> */}
        </div>
      </div>
     
    </div>
          ) : (
            <div className="Lotie_Main_Wrap">
              <div className="lotie_wrap">
                <lottie-player
                  src="https://assets10.lottiefiles.com/private_files/lf30_uqnbeq1u.json"
                  background="#48BA7E"
                  speed="1"
                  style={{ height: "92vh" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>
          )}
        </MobileView>
      </div>





</>











 
  );
};
